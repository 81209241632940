import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Image } from 'components/atoms';

const GallerySlide = ({
  data,
  className,
  imgWrapperRef,
  onLoadImg,
  multiple,
}) => {
  const { alt_text, source_url, caption, acf, media_details } = data || {};
  const { placeholder_base64 } = acf || {};

  return (
    <div className={cn('gallery-slide', 'swiper-slide', [className])}>
      <div
        className="gallery-slide__image"
        ref={imgWrapperRef}
        role="button"
        tabIndex="0"
        onMouseDown={e => e.preventDefault()}
        onContextMenu={e => e.preventDefault()}
      >
        <Image
          src={source_url}
          alt={alt_text}
          onLoad={onLoadImg}
          className="gallery-slide__image-wrapper"
          placeholder64={placeholder_base64}
          lazyLoading
          imageFit={multiple && 'cover'}
          image={media_details}
          imageAspect={media_details?.width / media_details?.height}
        />
      </div>
      {caption && (
        <div
          className="gallery-slide__caption"
          dangerouslySetInnerHTML={{ __html: caption }}
        ></div>
      )}
    </div>
  );
};

GallerySlide.defaultProps = {
  className: '',
  onLoadImg: null,
  imgWrapperRef: null,
};

GallerySlide.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  imgWrapperRef: PropTypes.object,
  onLoadImg: PropTypes.func,
  multiple: PropTypes.bool,
};

export default GallerySlide;
