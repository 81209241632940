import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Swiper from 'swiper';
import debounce from 'lodash/debounce';

import 'swiper/css/swiper.css';
import './index.scss';

import { Section, Container, Icon, Row, Column } from 'components/atoms';

import GallerySlide from 'components/molecules/GallerySlide';
import GalleryCaption from 'components/molecules/GalleryCaption';

import { DEBOUNCE } from 'constants/debounce';

const Gallery = ({ data, className, spacer, caseStudy }) => {
  const [swiperButtonTop, setSwiperButtonTop] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const activeImg = useRef(null);
  const sliderContainerRef = useRef(null);
  const [disabled, setDisabled] = useState(false);
  const galleryThumbs = useRef(null);

  const { gallery, background_fill, background_color } = data || {};

  const backgroundColorStyle = background_fill
    ? {
        backgroundColor: `${background_color}`,
      }
    : {};

  const handleResize = debounce(() => {
    if (activeImg.current) {
      setSwiperButtonTop(activeImg?.current.offsetHeight || 0);
    }
  }, DEBOUNCE[250]);

  const galleryCaptionMap =
    gallery &&
    gallery.map((item, index) => {
      return <GalleryCaption data={item} key={index} />;
    });

  const single = gallery.length <= 1;

  const galleryMap =
    gallery &&
    gallery.map((item, index) => {
      return (
        <GallerySlide
          data={item}
          key={index}
          imgWrapperRef={index === activeSlideIndex ? activeImg : null}
          onLoadImg={handleResize}
          mutltiple={!single}
        />
      );
    });

  const initSwiper = () => {
    const swiperThumbs = new Swiper(galleryThumbs.current, {
      effect: 'fade',
      speed: 1000,
    });

    new Swiper(sliderContainerRef.current, {
      speed: 500,
      navigation: {
        nextEl: '.gallery__swiper-button-next',
        prevEl: '.gallery__swiper-button-prev',
        disabledClass: 'gallery__swiper-button-disabled',
      },
      pagination: {
        el: '.gallery__swiper-pagination',
        type: 'bullets',
      },
      thumbs: { swiper: swiperThumbs },
      on: {
        init() {
          if (single) {
            setDisabled(true);
          }
          setActiveSlideIndex(this?.realIndex);
        },
        slideChange() {
          setActiveSlideIndex(this?.realIndex);
        },
      },
    });
  };

  useEffect(() => {
    initSwiper();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleResize();
  }, [activeImg]);

  return (
    <Section
      className={cn(
        'gallery',
        spacer && 'gallery_spacer',
        { 'case-study_bg-fill': background_fill },
        [className]
      )}
      style={backgroundColorStyle}
    >
      <Container>
        <Row>
          <Column lg="2" className="gallery__thumbs--hide">
            <div
              ref={galleryThumbs}
              className={cn('gallery__thumbs swiper-container')}
            >
              <div className="swiper-wrapper">{galleryCaptionMap}</div>
            </div>
          </Column>
          <Column
            lg="9"
            className={cn(
              caseStudy && 'gallery__swiper--wide',
              !caseStudy && 'gallery__swiper--narrow'
            )}
          >
            {data && (
              <div
                ref={sliderContainerRef}
                className={cn(
                  'gallery__swiper swiper-container',
                  disabled && 'disabled',
                  !caseStudy && 'gallery__swiper--small',
                  gallery.length === 1 && 'gallery__swiper--hide-pagination'
                )}
              >
                <div className="swiper-wrapper">{galleryMap}</div>

                <div
                  className="gallery__swiper-button-prev"
                  style={{
                    top: swiperButtonTop ? `${swiperButtonTop / 2}px` : '50%',
                  }}
                >
                  <Icon variant="slide-prev" />
                </div>
                <div
                  className="gallery__swiper-button-next"
                  style={{
                    top: swiperButtonTop ? `${swiperButtonTop / 2}px` : '50%',
                  }}
                >
                  <Icon variant="slide-next" />
                </div>
                <div className="gallery__swiper-pagination"></div>
              </div>
            )}
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

Gallery.defaultProps = {
  className: '',
};

Gallery.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  spacer: PropTypes.bool,
};
export default Gallery;
