import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const GalleryCaption = ({ data, className }) => {
  const { caption } = data || {};
  return (
    <div className={cn('gallery-slide', 'swiper-slide', [className])}>
      <div
        className={cn('gallery-slide__caption', {
          'gallery-slide__caption_hide': !caption,
        })}
        dangerouslySetInnerHTML={{ __html: caption }}
      ></div>
    </div>
  );
};

GalleryCaption.defaultProps = {
  className: '',
};

GalleryCaption.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default GalleryCaption;
